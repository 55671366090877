import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import InvoiceItemsTable from './InvoiceItemsTable'
import logo from '../../mpl-logo.png'

const styles = StyleSheet.create({
    page: { 
        paddingTop:150,paddingBottom:80,
        paddingLeft:10,
        fontSize: 8,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
      position: 'absolute',
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center'
    },
    
    logo: {
        width: 130,
        height: 60,
        marginTop:0
    },
    barcode: {
        width: 130,
        height: 20
    }
  });

  
  const Invoice=({invoice})=> {
      console.log(invoice)
   return (<Document>
      <Page style={styles.page} size="A5" wrap 
            >
      <View fixed style={{
                width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
                paddingLeft:  25,
                alignItems:"center",
                paddingRight: 10,
                paddingTop: 10
            }} >
                 <View
                style={{
                    width:"30%"
                }}
                >
                <Image style={styles.logo} src={logo}  />
                </View>
               <View style={{marginLeft:20, width:"30%"}} >
               <Text style={{
                     color: 'black',
                     fontSize: 13,
                     marginLeft:17,
                     fontFamily: 'Helvetica-Bold',
                     textDecoration:'underline',
                     
               }}>LAB ORDER</Text>
               
                <View style={{
                    margin: 'auto', width: 70, backgroundColor: '#F2F0F0', borderWidth: 1,
                    borderColor: 'black'
                }}>
                    <Text style={{ paddingTop: '3em', paddingLeft: '3.5em', paddingRight: '3.5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 7 }}>MPL ID : {invoice.invoice_id}</Text>
                </View>
               
                </View>
                <View style={{marginLeft:-10, marginTop:10, width:"40%"}}  >
                   <Image style={styles.barcode} src={invoice.barcode} />

                    <Text style={{
                        marginLeft: '5em', fontFamily: 'Helvetica-Bold', fontSize: 10, 
                        marginBottom:10,
                        marginTop:5
                    }}>{invoice.toAddress+" "+invoice.name}</Text>
                    <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7, 
                        borderTopColor: 'black',
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                        borderTopWidth:0.75
                     
                    }}>Age/Gender :  {invoice.age_gender}</Text>
                    <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7, 
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                    }}>Contact # :  {invoice.contact}</Text>
                    <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7, 
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                    }}>Ref By : {invoice.Ref_By}</Text>
                    {
                        invoice.Ref_No!=""
                        ? <Text style={{
                            marginLeft: '5em', paddingTop: '5em', fontSize: 7, 
                            borderBottomColor: 'black',
                            borderBottomWidth: 0.75,
                        }}>Ref No : {invoice.Ref_No}</Text>
                        :null
                    }
                 
            </View>
            </View>
            <View style={{width:'100%', height:'100%',marginTop:-60, paddingRight:10, paddingLeft:10}} 
           
           >
            
               <InvoiceItemsTable invoice={invoice}  
             />
           
           </View>
           <View fixed style={{ position: 'absolute', width: '100%', bottom: 0 }}>
            <View style={{
                paddingTop:30,
                paddingLeft:90,
                paddingBottom:30,
                paddingRight:80,
                flexDirection:'column',
            }}>
       
<Text style={{

    textAlign: 'center',
    padding: 2,
    width:"100%",
    borderTopColor:'black',
    borderTopWidth:0.5,
    fontSize: 7,
    color: 'black'
}}>
    {"User : "}<Text style={{
        fontFamily: 'Helvetica-Bold'
    }}>{invoice.User_Name}</Text>
</Text>
<Text style={{

textAlign: 'center',
padding: 2,
width:"100%",
borderTopColor:'black',
borderTopWidth:0.5,
borderBottomWidth:0.5,
fontSize: 7,
color: 'black'
}}>
{"Date Book : "}<Text style={{
    fontFamily: 'Helvetica-Bold'
}}>{invoice.invoice_date}</Text>{" at "}<Text style={{
    fontFamily: 'Helvetica-Bold'
}}>{invoice.invoice_time}</Text>
</Text>

</View>
</View>



      </Page>
    </Document>
  )
        }
  
export default Invoice