import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'column',
        marginTop: 24,
    },
    reportTitle:{
        color: 'black',
        fontSize: 9,
        fontFamily: 'Helvetica-Bold',
        textAlign: 'left',
    },
    PrintTitle:{
      color: 'black',
      fontSize: 9,
      textAlign: 'left',
  },
    ServiceTitle:{
      color: 'black',
      fontSize: 8,
      fontFamily: 'Helvetica-Bold',
      textAlign: 'left',
  },
    addressTitle:{
      color: 'black',
      fontSize: 7,
      textAlign: 'left',
  }
  });


  const InvoiceTitle = ({item}) => (
    <View style={styles.titleContainer}>
      <View
      style={{
        position:"absolute",
        marginTop:-35
      }}
      >
          <Text style={styles.reportTitle}>{item.BranchTitle}</Text>
        <Text style={styles.addressTitle}>{item.BranchAddress}</Text>
        <Text style={styles.addressTitle}>{"Phone : "+item.BranchPhone}</Text>
      
       
      
     </View>
     <Text style={styles.ServiceTitle}>{item.BranchTiming}</Text>
  <Text style={{
      color: 'black',
      fontSize: 9,
      fontFamily: 'Helvetica',
      textAlign: 'left',
  }}>{item.Panel_Name.includes("Air MPL") ? "COVID-19 PCR FOR KSA": item.Panel_Name}</Text>
    </View>
  );
  
  export default InvoiceTitle