import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Invoice from '../../Invoice/invoice';
import    InvoiceTableHeader from './InvoiceTableHeader'




const InvoiceTableRow = ({ items }) => {
    var counter=0
    const rows = items.map(item =>{

        
            counter++
    
            return (
                <View>
        {/* { counter%13==0
            ?
                 <View style={ {
                    flexDirection: 'row',
                    alignItems: 'center',
                    width:"100%",
                    height:"120em"
                    }} key={item.test_title} >
                        </View>
                        :null
            } */}
            {/* {
                counter%13==0 ?
                <InvoiceTableHeader />
                : null
            } */}

        <View key={item.sn + "321323"}>
            <View style={{
                flexDirection: 'row',
                borderBottomColor: 'BLACK',
                borderBottomWidth: 0.5,
                alignItems: 'center',
                width: "100%",
                fontSize: 7,
                fontStyle: 'bold'
            }} key={item.sn}>
                <Text style={{

                    width: '10%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 0.5,
                    height: '100%',
                    padding: 5,
                    fontSize: 7,
                }}>{item.sn}</Text>
                {/* <Text style={{

                    width: '50%',
                    fontFamily: 'Helvetica-Bold',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 0.5,
                    fontSize: 6,
                    height: '100%',
                    padding: 5


                }}>{item.test_title}</Text> */}
                                        <View style={{

width: '50%',
// fontFamily: 'Helvetica-Bold',
// textAlign: 'left',
borderLeftColor: 'black',
borderLeftWidth: 0.5,
// fontSize: 6,
height: '100%',
padding: 5


}}>
                <Text style={{
                    fontFamily: 'Helvetica-Bold',
                    textAlign: 'left',   
                    fontSize: 6,
                }}>{item.test_title}</Text>
                  <Text style={{
textAlign: 'left',
fontSize: 6,
}}>{item.tests_line_details}</Text>
</View>
                <Text style={{

                    width: '40%',
                    textAlign: 'left',
                    borderColor: 'black',
                    borderLeftWidth: 0.5,
                    borderRightWidth: 0.5,
                    fontSize: 6,
                    padding: 5,
                    height: '100%'


                }}>{item.reporting_date}</Text>

            </View>
         



        </View>
        </View>
    )
            })
    return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow