import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import HelpIcon from '@material-ui/icons/Help';
import { isMobile } from 'react-device-detect';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import Invoice from './PDF/Invoice.js'

import LabOrder from './Lab_Order/Invoice.js'


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import RefreshIcon from '@material-ui/icons/Refresh';

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';


import CovidTemplate from './Covid_PDF/CovidTemplate'


import OfficialCovidTemplate from './Official_Template/OfficialCovidTemplate'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}
var temp = 
    [
      { id: 'patient_invoice', label: 'Invoice' },
      { id: 'lab_order', label: 'Lab Order' },
      { id: 'download', label: 'Report' },
    ]

const columns =
  [{ id: 'id', label: 'Invoice ID' },
  { id: 'name', label: 'Patient Name' },
  { id: 'title', label: 'Test Name' },
  { id: 'code', label: 'Test Code' },
  { id: 'branch', label: 'Branch' },
  { id: 'status', label: 'Status' },
  ...temp
  ]
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
  return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return addZero(hours) + ':' + addZero(minutes);
}

export default function Lab_Tests() {
  const classes = useStyles();
  var date = new Date()
  var temp_role = localStorage.getItem('role')


  const [selected, setSelected] = React.useState({
    reports: []
  })

  const [OpenCovidTemplate, setCovidTemplate] = React.useState(false)

  const [OpenOfficialCovidTemplate, setOfficialCovidTemplate] = React.useState(false)

  const [patient_invoice, setPatientInvoice] = React.useState(false)
  const [from, setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel, setPanel] = React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);


  const [selectedCovid, setSelectedCovid] = React.useState({
    reports: []
  })
  const [selectedOfficialCovidReport, setSelectedOfficialCovidReport] = React.useState({
    reports: []
  })


  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)

  const [covidButton, setCovidButton] = React.useState(false)

  const [panelList, setpanelList] = React.useState([])
  const [laborder, setLabOrder] = React.useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branches, setBranches] = React.useState([])
  const [branch, setBranch] = React.useState("")
  const [selectBranch, setSelectBranch] = React.useState([])
  const [selectOrders, setSelectedOrders] = React.useState([])
  const [state, setState] = React.useState({
    status: 'All',
  });
  var svgString2Image = (svgString, width, height, format, callback) => {

    var svgStringFormat = window.atob(svgString)
    var temp = svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
    // set default for format parameter
    format = format ? format : 'png';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL('image/' + format);
      // pass png data URL to callbac
      callback(pngData)
    }; // end async
    image.src = svgData

  }

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://ccp.mpl-labs.pk/login"
    }
    if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://ccp.mpl-labs.pk"
    }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
      setBranches(response)

    })
    var patient_id = localStorage.getItem('panel_code')
    var role = localStorage.getItem('role')

    var from = new Date();


    var branchID = localStorage.getItem('branch_id')

    var to = new Date();
    var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
    var vSearchStr = JSON.stringify(SearchFormData);

    var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestListwithStatus?vQuery=" + vSearchStr + "&vUID=" + patient_id + "&vRole=Panel"

    fetch(url).then((res) => res.json())
      .then((result) => {
        console.log("Lab_Tests (Invoice Record) response 249 Api response ",result)
        var the_rows = []
        result.map((data) => {
          var br_title = ""

          if (data.B_TiTLE == "METROPOLE") {
            br_title = "Islamabad"
          } else {

            br_title = data.B_TiTLE.split('MPL,')[1]

          }
          var obj = {
            id: data.Invoice_ID,
            name: data.NAME_F + " " + data.NAME_L,
            title: data.T_TiTLE,
            status: data.Test_Status,
            cnic: data.CNIC,
            mobile: data.Mobile_No,
            passport: data.Passport_No,
            reference_no: data.Reference_No,
            code: data.T_CODE,
            Panel_Code: data.Panel_Code,
            Panel_Name: data.Panel_Name,
            branch: br_title,
            dateObject: new Date(data.ACCESS_DATE),
            refresh: <center>
              <Button variant="contained" style={{ backgroundColor: 'skyblue', color: 'white' }} disableElevation onClick={() => {
                if (data.Test_Status == 'Results Ready') {
                  window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF_BlobRefresh/' + data.InV_AuX_ID)
                } else {
                  alert('Results not Ready!!')
                }
              }}>
                <  ReceiptIcon />
              </Button>
            </center>
            ,
            barcode: <center>
              <Button variant="contained" style={{ backgroundColor: 'white', color: 'black' }} key={data.Invoice_ID} disableElevation onClick={() => {
                if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                  var obj1 = {
                    sample_id: data.Invoice_ID,
                    age_gender: data.Age_gender,
                    test_title: "Nasal Swab/Nasal Secretions",
                    date: new Date().ddmmyyy(),
                    mpl_id: data.Invoice_ID,
                    name: data.NAME_F + " " + data.NAME_L,
                    patient_id: data.SYSTEM_ID,
                    time: formatAMPM(new Date()),

                  }
                  var url = "http://localhost:1000/GETBarcode"
                  fetch(url, {
                    method: "POST",
                    body: JSON.stringify(obj1),
                    headers: {
                      'Content-Type': "application/json"
                    }
                  }).then(() => {
                    alert("Print Request Sent")
                  })
                } else {
                  alert("Only for COVID-19 Reports")
                }
              }} >
                Barcode
          </Button>
            </center>
            ,
            patient_invoice: <center>
               {/* this is Invoice  */}
              <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.Invoice_ID} disableElevation onClick={() => {
                var temp = {
                  Invoice_No:data.Invoice_ID+""
                }
                fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                  method: 'POST',
                  'Content-Type': "application/json",
                  body: JSON.stringify(temp)
                }).then((res) => res.json()).then(async (record) => {
                  console.log("Invoice Api response when click on button 329 ",record)
                  svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                    var flight_date = new Date(data.Flight_Date).ddmmyyy()
                    var print_date = new Date().ddmmyyy()
                    var invoice_date = new Date(data.ACCESS_DATE).ddmmyyy()
                    var orders = JSON.parse(record[0].Array_Test_Code)

                    var counter = -1
                    var up_order = await orders.map((item) => {
                      counter++
                      return {
                        sn: counter + 1,
                        test_title: JSON.parse(record[0].Array_Test_Name)[counter],
                        reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[counter],
                        fee: (JSON.parse(record[0].Array_Price)[counter]).toFixed(2),
                        disc: JSON.parse(record[0].Array_Discount)[counter].toFixed(2),
                        net_value: JSON.parse(record[0].Array_Payable)[counter].toFixed(2),
                        UrgentCase: data.UrgentCase,
                        branch: data.B_TiTLE,
                        User_Name: data.User_Name,
                        cnic: data.CNIC,
                        name: data.NAME_F + " " + data.NAME_L,
                        passport: data.Passport_No,
                        user_id: data.User_ID,
                        //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                      }


                    })

                    Promise.all(up_order).then((values) => {
                      var obj1 = {
                        branch: data.B_TiTLE,
                        barcode: barcode,
                        name: data.NAME_F + " " + data.NAME_L,
                        age_gender: data.Age_gender,
                        password: data.Patient_PW,
                        patient_id: data.SYSTEM_ID,
                        print_time: formatAMPM(new Date()),
                        print_date: print_date,
                        Ref_By: record[0].Ref_By,
                        Ref_No: record[0].Ref_No,
                        Invoice_Remarks: record[0].Invoice_Remarks,
                        Patient_Comments: record[0].Patient_Comments,
                        invoice_time: msToTime(data.ACCESS_TiME),
                        Entitlement_Name: record[0].Entitlement_Name,
                        invoice_date: invoice_date,
                        Discount_Total: record[0].Discount_Total.toFixed(2),
                        Invoice_Value: record[0].Invoice_Value.toFixed(2),
                        User_Name: data.User_Name,
                        cnic: data.CNIC,

                        BranchAddress:record[0].BranchAddress,
                        BranchPhone:record[0].BranchPhone,
                        BranchTiming:record[0].BranchTiming,
                        BranchTitle:record[0].BranchTitle,

                        Ticket_No: record[0].Ticket_No,
                        invoice_id: data.Invoice_ID,
                        contact: data.Mobile_No,
                        Panel_Name: record[0].Panel_Name,
                        passport_no: data.Passport_No,
                        flight_date: flight_date,
                        flight_no: data.Flight_no,
                        flight_time: msToTime(data.Flight_Time),
                        toAddress: data.To_Address,
                        reports: values,
                        Invoice_Payable: record[0].Invoice_Payable.toFixed(2),
                        Invoice_Received: record[0].Invoice_Received.toFixed(2),
                        Invoice_Balance: record[0].Invoice_Balance.toFixed(2)

                      }
                      setSelected(obj1)

                      setTimeout(() => {
                        setPatientInvoice(true)

                      }, 500);
                    })

                  })


                })

              }}>
                <  ReceiptIcon />
              </Button>
            </center>
            ,
            lab_order: <center>
               {/* this is Lab Order  */}
              <Button variant="contained" style={{ backgroundColor: 'purple', color: 'white' }} key={data.Invoice_ID} disableElevation onClick={() => {
                var temp = {
                  Invoice_No:data.Invoice_ID+""
                }
                fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                  method: 'POST',
                  'Content-Type': "application/json",
                  body: JSON.stringify(temp)
                }).then((res) => res.json()).then(async (record) => {
                  svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                    var flight_date = new Date(data.Flight_Date).ddmmyyy()
                    var print_date = new Date().ddmmyyy()
                    var invoice_date = new Date(data.ACCESS_DATE).ddmmyyy()
                    var orders = JSON.parse(record[0].Array_Test_Code)
                    var up_order = []
                    var counter = 0
                    orders.map((item) => {

                      up_order.push({
                        sn: counter + 1,
                        test_title: JSON.parse(record[0].Array_Test_Name)[counter],
                        reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[counter],
                        fee: (JSON.parse(record[0].Array_Price)[counter]).toFixed(2),
                        disc: JSON.parse(record[0].Array_Discount)[counter].toFixed(2),
                        net_value: JSON.parse(record[0].Array_Payable)[counter].toFixed(2),
                        UrgentCase: data.UrgentCase,
                        branch: data.B_TiTLE,
                        User_Name: data.User_Name,
                        cnic: data.CNIC,
                        name: data.NAME_F + " " + data.NAME_L,
                        passport: data.Passport_No,
                        user_id: data.User_ID,
                         //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                      })
                      counter++

                    })

                    var obj1 = {
                      branch: data.B_TiTLE,
                      barcode: barcode,
                      name: data.NAME_F + " " + data.NAME_L,
                      age_gender: data.Age_gender,
                      password: data.Patient_PW,
                      Ref_By: record[0].Ref_By,
                      Ref_No: record[0].Ref_No,
                      patient_id: data.SYSTEM_ID,
                      cnic: data.CNIC,
                      print_time: formatAMPM(new Date()),
                      print_date: print_date,
                      invoice_time: msToTime(data.ACCESS_TiME),
                      invoice_date: invoice_date,
                      User_Name: data.User_Name,
                      Invoice_Remarks: record[0].Invoice_Remarks,
                      Patient_Comments: record[0].Patient_Comments,
                      invoice_id: data.Invoice_ID,
                      contact: data.Mobile_No,
                      Panel_Name: record[0].Panel_Name,
                      Airline: data.Airline,
                      Ticket_No: record[0].Ticket_No,
                      Destination: data.Destination,
                      passport_no: data.Passport_No,
                      flight_date: flight_date,
                      flight_no: data.Flight_no,
                      flight_time: msToTime(data.Flight_Time),
                      toAddress: data.To_Address,
                      reports: up_order,
                      Invoice_Payable: record[0].Invoice_Payable.toFixed(2),
                      Invoice_Received: record[0].Invoice_Received.toFixed(2),
                      Invoice_Balance: record[0].Invoice_Balance.toFixed(2)

                    }
                    setSelectedOrders(obj1)
                    setTimeout(() => {
                      setLabOrder(true)

                    }, 1000);


                  })


                })

              }}>
                <  ReceiptIcon />
              </Button>
            </center>
            ,
            invoice: <center>
              <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} disableElevation onClick={() => {
                if (data.T_TiTLE != "Covid-19 RNA by PCR") {
                  window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetInvoicePDF/' + data.InV_AuX_ID)
                } else {
                  alert("Kindly view COVID-19 Invoice")
                }
              }}>
                <  ReceiptIcon />
              </Button>
            </center>
            ,
            download:
              <center>
                {/* this is Report  */}
                {
                  data.DOnotShowUnpaidReport == true 
                    ?

                    <Button onClick={() => { alert("Report can't be shown until Balance is Due") }}>
                      <HelpIcon />
                    </Button>
                   
                      :
                      <Button variant="contained" color="secondary" disableElevation onClick={() => {
                        if (data.Test_Status == 'Results Ready') {
                          window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)

                        } else {
                          alert('Results not Ready!!')
                        }
                      }} >
                        <PictureAsPdfSharpIcon />
                      </Button>

                }

              </center>


            ,
            official_covid_report:
              <center>
                {
                  data.T_TiTLE == "Covid-19 RNA by PCR" ?
                    <Button variant="contained" color="info" key={data.Invoice_ID} disabled={covidButton} onClick={() => {
                      if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                        if (data.Test_Status == 'Results Ready') {
                          setCovidButton(true)
                          fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + data.InV_AuX_ID).then(res => res.json()).then((response) => {

                            svgString2Image(response[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
                              svgString2Image(response[0].barcode, 800, 600, 'png', (barcode) => {
                                svgString2Image(response[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
                                  var test_name = response[0].test_name
                                  if (response[0].test_name == "") {
                                    test_name = "Covid-19 RNA by PCR"

                                  }
                                  var cnic = response[0].cnic + ""
                                  var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")

                                  var covid_record = {
                                    barcode: barcode,
                                    invoice_id: response[0].invoice_id,
                                    QR_Code_Online: QR_Code_Online,
                                    QR_Code_Offline: QR_Code_Offline,
                                    name: response[0].name,
                                    ShouldSampleDateTimePrint: response[0].ShouldSampleDateTimePrint,
                                    passport: response[0].passport,
                                    title: test_name,
                                    age_gender: response[0].age_gender,
                                    specimen_date: response[0].specimen_date,
                                    specimen_time: response[0].specimen_Time,
                                    cnic: validated_cnic,
                                    list: [{
                                      title: test_name,
                                      reports: [{
                                        result: response[0].result,
                                        test_name: test_name,
                                        comments: "",
                                        ref_range: "",
                                        prev1: "--",
                                        prev2: "--"

                                      }],
                                      id: response[0].login_id,
                                      password: response[0].Password
                                    }],
                                    toAddress: response[0].toAddress,
                                    result_date: response[0].result_date,
                                    result_time: response[0].result_time,
                                    user_id: response[0].User_Name.toUpperCase()
                                  }

                                  setSelectedOfficialCovidReport(covid_record)
                                  setTimeout(() => {
                                    setOfficialCovidTemplate(true)

                                  }, 800)
                                })
                              })
                            })
                          })
                        } else {
                          alert("Results not Ready Yet")
                        }


                      } else {
                        alert("Only for COVID-19 Reports")
                      }
                    }}>
                      {covidButton ? "Loading......" : <  PictureAsPdfSharpIcon />}
                    </Button>
                    :
                    null
                }

              </center>
          }
          the_rows.push(obj)

        })

        setRows(the_rows)
        setOrg_Rows(the_rows)
        setLoading(false)
      })
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetAllPanelsList").then((res) => res.json())
      .then((result) => {
        setpanelList(result)
      })
    if (localStorage.getItem('org_branch_id') == '0') {
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
        setBranches(response)
        var obj = {}
        response.map((item) => {
          obj[item.BranchID] = item.BranchName
        })
        setSelectBranch(obj)

        setBranch(localStorage.getItem('branch_id'))
      })
    }


  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <>
      <Paper className={classes.root}>
        <div style={{ padding: '1em', textAlign: 'center' }}>
          {localStorage.getItem('org_branch_id') == '0' ? <h3 style={{ color: 'red' }}>Branch Selection will change the Booking, Invoicing and Reporting Branch</h3> : null}
        </div>
        <GridContainer style={{ padding: '2em' }}>
          <GridItem xs={6} sm={6} md={2} lg={2}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="From"
                format="dd/MM/yyyy"
                value={from}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setFrom(date)}
              />
            </MuiPickersUtilsProvider>
          </GridItem>
          {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          }

          <GridItem xs={6} sm={6} md={2} lg={2}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="To"
                format="dd/MM/yyyy"
                value={to}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setTo(date)}
              />
            </MuiPickersUtilsProvider>

          </GridItem>
          {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          }
          <GridItem xs={6} sm={6} md={2} lg={2}>
            <FormControl variant="filled" className={classes.formControl} style={{ width: "-webkit-fill-available" }} >
              <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={state.status}
                onChange={handleChange}
                inputProps={{
                  name: 'status',
                  id: 'filled-age-native-simple',
                }}
              >
                <MenuItem aria-label="Select" value="All" >All</MenuItem>
                <MenuItem value={'Just Booked'}>Just Booked</MenuItem>
                <MenuItem value={'Only Phlebotomy Done'}>Only Phlebotomy Done</MenuItem>
                <MenuItem value={'Lying Pending Results'}>Lying Pending Results</MenuItem>
                <MenuItem value={'Results Done But not Signed'}>Results Done But not Signed</MenuItem>
                <MenuItem value={'Results Ready'}>Results Ready</MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          {
            localStorage.getItem('org_branch_id') == '0' ?
              <GridItem xs={12} sm={12} md={2} lg={2}  >
                <FormControl variant="filled" className={classes.formControl} style={{ width: "10em" }} >
                  <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={branch}
                    onChange={(e) => {
                      var result = window.confirm('Are you Sure ?. Your want to change Branch?')
                      if (result) {
                        setBranch(e.target.value)
                        localStorage.setItem('branch_id', e.target.value)
                        localStorage.setItem('branch_name', selectBranch[e.target.value])
                        window.location.reload()
                      }

                    }}
                  >
                    <MenuItem value={"0"} disabled>Select</MenuItem>
                    {
                      branches.map((item) => {
                        return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>

                      })
                    }
                  </Select>
                </FormControl>
              </GridItem>
              : null
          }
          <GridItem xs={12} sm={12} md={2} lg={2} style={{ margin: 'auto' }} >

            <Button variant="contained" color="primary" disableElevation disabled={loading}
              onClick={() => {
                setPage(0)
                document.getElementById('search_data').value = ""
                var role = "Panel"
                var patient_id = localStorage.getItem('panel_code')
                setLoading(true)

                var branchID = localStorage.getItem('branch_id')
                var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
                var vSearchStr = JSON.stringify(SearchFormData);

                var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestListwithStatus?vQuery=" + vSearchStr + "&vUID=" + patient_id + "&vRole=" + role

                fetch(url).then((res) => res.json())
                  .then((result) => {
                    console.log("Lab_Tests (Invoice Record) Search response 249 Api response ",result)
                    var the_rows = []
                    var name = localStorage.getItem('patient_name')
                    result.map((data) => {
                      var br_title = ""
                      if (data.B_TiTLE == "METROPOLE") {
                        br_title = "Islamabad"
                      } else {

                        br_title = data.B_TiTLE.split('MPL,')[1]


                      }
                      var obj = {
                        id: data.Invoice_ID,
                        name: data.NAME_F + " " + data.NAME_L,
                        title: data.T_TiTLE,
                        status: data.Test_Status,
                        cnic: data.CNIC,
                        passport: data.Passport_No,
                        mobile: data.Mobile_No,
                        reference_no: data.Reference_No,
                        code: data.T_CODE,
                        Panel_Code: data.Panel_Code,
                        Panel_Name: data.Panel_Name,
                        branch: br_title,
                        dateObject: new Date(data.ACCESS_DATE),
                        refresh: <center>
                          <Button variant="contained" style={{ backgroundColor: 'skyblue', color: 'white' }} disableElevation onClick={() => {
                            if (data.Test_Status == 'Results Ready') {

                              window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF_BlobRefresh/' + data.InV_AuX_ID)
                            } else {
                              alert('Results not Ready!!')
                            }
                          }}>
                            <  ReceiptIcon />
                          </Button>
                        </center>
                        ,
                        barcode: <center>
                          <Button variant="contained" style={{ backgroundColor: 'white', color: 'black' }} key={data.Invoice_ID} disableElevation onClick={() => {
                            if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                              var obj1 = {
                                sample_id: data.Invoice_ID,
                                age_gender: data.Age_gender,
                                test_title: "Nasal Swab/Nasal Secretions",
                                date: new Date().ddmmyyy(),
                                mpl_id: data.Invoice_ID,
                                name: data.NAME_F + " " + data.NAME_L,
                                patient_id: data.SYSTEM_ID,
                                time: formatAMPM(new Date()),

                              }
                              var url = "http://localhost:1000/GETBarcode"
                              fetch(url, {
                                method: "POST",
                                body: JSON.stringify(obj1),
                                headers: {
                                  'Content-Type': "application/json"
                                }
                              }).then(() => {
                                alert("Print Request Sent")
                              })
                            } else {
                              alert("Only for COVID-19 Reports")
                            }
                          }} >
                            Barcode
       </Button>
                        </center>
                        ,
                        patient_invoice: <center>
                          <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.Invoice_ID} disableElevation onClick={() => {
                            var temp = {
                              Invoice_No:data.Invoice_ID+""
                            }
                            fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                              method: 'POST',
                              'Content-Type': "application/json",
                              body: JSON.stringify(temp)
                            }).then((res) => res.json()).then(async (record) => {
                              svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                                var flight_date = new Date(data.Flight_Date).ddmmyyy()
                                var print_date = new Date().ddmmyyy()
                                var invoice_date = new Date(data.ACCESS_DATE).ddmmyyy()
                                var orders = JSON.parse(record[0].Array_Test_Code)
                                var up_order = []
                                var counter = 0
                                orders.map((item) => {

                                  up_order.push({
                                    sn: counter + 1,
                                    test_title: JSON.parse(record[0].Array_Test_Name)[counter],
                                    reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[counter],
                                    fee: (JSON.parse(record[0].Array_Price)[counter]).toFixed(2),
                                    disc: JSON.parse(record[0].Array_Discount)[counter].toFixed(2),
                                    net_value: JSON.parse(record[0].Array_Payable)[counter].toFixed(2),
                                    UrgentCase: data.UrgentCase,
                                    branch: data.B_TiTLE,
                                    User_Name: data.User_Name,
                                    cnic: data.CNIC,
                                    name: data.NAME_F + " " + data.NAME_L,
                                    passport: data.Passport_No,
                                    user_id: data.User_ID,
                                       //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                                  })
                                  counter++

                                })

                                var obj1 = {
                                  branch: data.B_TiTLE,
                                  barcode: barcode,
                                  name: data.NAME_F + " " + data.NAME_L,
                                  age_gender: data.Age_gender,
                                  password: data.Patient_PW,
                                  patient_id: data.SYSTEM_ID,
                                  print_time: formatAMPM(new Date()),
                                  print_date: print_date,
                                  invoice_time: msToTime(data.ACCESS_TiME),
                                  invoice_date: invoice_date,
                                  Ref_By: record[0].Ref_By,
                                  Invoice_Remarks: record[0].Invoice_Remarks,
                                  Patient_Comments: record[0].Patient_Comments,
                                  Ref_No: record[0].Ref_No,
                                  Entitlement_Name: record[0].Entitlement_Name,
                                  User_Name: data.User_Name,
                                  cnic: data.CNIC,


                                  BranchAddress:record[0].BranchAddress,
                                  BranchPhone:record[0].BranchPhone,
                                  BranchTiming:record[0].BranchTiming,
                                  BranchTitle:record[0].BranchTitle,
                                  
                                  Discount_Total: record[0].Discount_Total.toFixed(2),
                                  Invoice_Value: record[0].Invoice_Value.toFixed(2),
                                  invoice_id: data.Invoice_ID,
                                  contact: data.Mobile_No,
                                  Ticket_No: record[0].Ticket_No,
                                  Panel_Name: record[0].Panel_Name,
                                  passport_no: data.Passport_No,
                                  flight_date: flight_date,
                                  flight_no: data.Flight_no,
                                  flight_time: msToTime(data.Flight_Time),
                                  toAddress: data.To_Address,
                                  reports: up_order,
                                  Invoice_Payable: record[0].Invoice_Payable.toFixed(2),
                                  Invoice_Received: record[0].Invoice_Received.toFixed(2),
                                  Invoice_Balance: record[0].Invoice_Balance.toFixed(2)

                                }
                                setSelected(obj1)
                                setTimeout(() => {
                                  setPatientInvoice(true)

                                }, 500);


                              })


                            })

                          }}>
                            <  ReceiptIcon />
                          </Button>
                        </center>
                        ,
                        lab_order: <center>
                          <Button variant="contained" style={{ backgroundColor: 'purple', color: 'white' }} key={data.Invoice_ID} disableElevation onClick={() => {
                            var temp = {
                              Invoice_No:data.Invoice_ID+""
                            }
                            fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                              method: 'POST',
                              'Content-Type': "application/json",
                              body: JSON.stringify(temp)
                            }).then((res) => res.json()).then(async (record) => {
                              svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                                var flight_date = new Date(data.Flight_Date).ddmmyyy()
                                var print_date = new Date().ddmmyyy()
                                var invoice_date = new Date(data.ACCESS_DATE).ddmmyyy()
                                var orders = JSON.parse(record[0].Array_Test_Code)
                                var up_order = []
                                var counter = 0
                                orders.map((item) => {

                                  up_order.push({
                                    sn: counter + 1,
                                    test_title: JSON.parse(record[0].Array_Test_Name)[counter],
                                    reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[counter],
                                    fee: (JSON.parse(record[0].Array_Price)[counter]).toFixed(2),
                                    disc: JSON.parse(record[0].Array_Discount)[counter].toFixed(2),
                                    net_value: JSON.parse(record[0].Array_Payable)[counter].toFixed(2),
                                    UrgentCase: data.UrgentCase,
                                    branch: data.B_TiTLE,
                                    User_Name: data.User_Name,
                                    cnic: data.CNIC,
                                    name: data.NAME_F + " " + data.NAME_L,
                                    passport: data.Passport_No,
                                    user_id: data.User_ID,
                                     //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                                  })
                                  counter++

                                })

                                var obj1 = {
                                  branch: data.B_TiTLE,
                                  barcode: barcode,
                                  name: data.NAME_F + " " + data.NAME_L,
                                  age_gender: data.Age_gender,
                                  password: data.Patient_PW,
                                  Ref_By: record[0].Ref_By,
                                  Ref_No: record[0].Ref_No,
                                  patient_id: data.SYSTEM_ID,
                                  cnic: data.CNIC,
                                  print_time: formatAMPM(new Date()),
                                  print_date: print_date,
                                  invoice_time: msToTime(data.ACCESS_TiME),
                                  invoice_date: invoice_date,
                                  User_Name: data.User_Name,
                                  Invoice_Remarks: record[0].Invoice_Remarks,
                                  Patient_Comments: record[0].Patient_Comments,
                                  invoice_id: data.Invoice_ID,
                                  contact: data.Mobile_No,
                                  Panel_Name: record[0].Panel_Name,
                                  Airline: data.Airline,
                                  Ticket_No: record[0].Ticket_No,
                                  Destination: data.Destination,
                                  passport_no: data.Passport_No,
                                  flight_date: flight_date,
                                  flight_no: data.Flight_no,
                                  flight_time: msToTime(data.Flight_Time),
                                  toAddress: data.To_Address,
                                  reports: up_order,
                                  Invoice_Payable: record[0].Invoice_Payable.toFixed(2),
                                  Invoice_Received: record[0].Invoice_Received.toFixed(2),
                                  Invoice_Balance: record[0].Invoice_Balance.toFixed(2)

                                }
                                setSelectedOrders(obj1)
                                setTimeout(() => {
                                  setLabOrder(true)

                                }, 1000);


                              })


                            })

                          }}>
                            <  ReceiptIcon />
                          </Button>
                        </center>
                        ,

                        invoice: <center>
                          <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} disableElevation onClick={() => {
                            if (data.T_TiTLE != "Covid-19 RNA by PCR") {
                              window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetInvoicePDF/' + data.InV_AuX_ID)
                            } else {
                              alert("Kindly view COVID-19 Invoice")
                            }
                          }}>
                            <  ReceiptIcon />
                          </Button>
                        </center>,

                        download: <center>
                          {
                            data.DOnotShowUnpaidReport == true 
                              ?
          
                              <Button onClick={() => { alert("Report can't be shown until Balance is Due") }}>
                                <HelpIcon />
                              </Button>
                             
                                :
                                <Button variant="contained" color="secondary" disableElevation onClick={() => {
                                  if (data.Test_Status == 'Results Ready') {
                                    window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)
          
                                  } else {
                                    alert('Results not Ready!!')
                                  }
                                }} >
                                  <PictureAsPdfSharpIcon />
                                </Button>
          
                          }
          
                        </center>
                        ,
                        official_covid_report:
                          <center>
                            {
                              data.T_TiTLE == "Covid-19 RNA by PCR" ?
                                <Button variant="contained" color="info" key={data.Invoice_ID} disabled={covidButton} onClick={() => {
                                  if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                                    if (data.Test_Status == 'Results Ready') {
                                      setCovidButton(true)
                                      fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + data.InV_AuX_ID).then(res => res.json()).then((response) => {

                                        svgString2Image(response[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
                                          svgString2Image(response[0].barcode, 800, 600, 'png', (barcode) => {
                                            svgString2Image(response[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
                                              var test_name = response[0].test_name
                                              if (response[0].test_name == "") {
                                                test_name = "Covid-19 RNA by PCR"

                                              }
                                              var cnic = response[0].cnic + ""
                                              var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")

                                              var covid_record = {
                                                barcode: barcode,
                                                invoice_id: response[0].invoice_id,

                                                name: response[0].name,
                                                ShouldSampleDateTimePrint: response[0].ShouldSampleDateTimePrint,
                                                passport: response[0].passport,
                                                title: test_name,
                                                age_gender: response[0].age_gender,
                                                specimen_date: response[0].specimen_date,
                                                specimen_time: response[0].specimen_Time,
                                                QR_Code_Online: QR_Code_Online,
                                                QR_Code_Offline: QR_Code_Offline,
                                                cnic: validated_cnic,
                                                list: [{
                                                  title: test_name,
                                                  reports: [{
                                                    result: response[0].result,
                                                    test_name: test_name,
                                                    comments: "",
                                                    ref_range: "",
                                                    prev1: "--",
                                                    prev2: "--"

                                                  }],
                                                  id: response[0].login_id,
                                                  password: response[0].Password
                                                }],
                                                toAddress: response[0].toAddress,
                                                result_date: response[0].result_date,
                                                result_time: response[0].result_time,
                                                user_id: response[0].User_Name.toUpperCase()
                                              }

                                              setSelectedOfficialCovidReport(covid_record)
                                              setTimeout(() => {
                                                setOfficialCovidTemplate(true)

                                              }, 800)
                                            })
                                          })
                                        })

                                      })
                                    } else {
                                      alert("Results not Ready Yet")
                                    }


                                  } else {
                                    alert("Only for COVID-19 Reports")
                                  }
                                }}>
                                  {covidButton ? "Loading......" : <  PictureAsPdfSharpIcon />}
                                </Button>
                                :
                                null
                            }

                          </center>
                      }
                      if (state.status == "All") {
                        the_rows.push(obj)

                      } else {
                        if (state.status.trim() == data.Test_Status.trim()) {
                          the_rows.push(obj)
                        }
                      }


                    })

                    setRows(the_rows)
                    setOrg_Rows(the_rows)
                    setLoading(false)
                    setPage(0)
                  })

              }}
            >
              Search<SearchIcon />
            </Button>

          </GridItem>
        </GridContainer>
        <div align="right">

          <TextField id="standard-basic" id="search_data" label="Search by Invoice ID, Passport No , Reference No , CNIC , Patient Name , Mobile No , Test Name and Test Code"
            style={{ maxWidth: '50em' }}
            fullWidth={true}

            onChange={async (e) => {
              var lab_tests = []
              var text = e.target.value
              var data = await org_rows.map((item) => {
                if (item.title == "Covid-19 RNA by PCR") {
                  if (item.cnic.includes(text)) {
                    lab_tests.push(item)
                    return
                  }
                  if (item.passport.toLowerCase().includes(text.toLowerCase())) {
                    lab_tests.push(item)
                    return
                  }
                  if (item.reference_no.toLowerCase().includes(text.toLowerCase())) {
                    lab_tests.push(item)
                    return
                  }
                }
                if (item.mobile.includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                if (item.title.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                if (item.name.trim().toLowerCase().includes(text.trim().toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.code.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.id.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                return item

              })
              Promise.all(data).then((item) => {

                setRows(lab_tests)

              })

            }}
          />

        </div>
        {loading ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          :
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                        {columns.map((column) => {
                          const value = row[column.id];
                          var status_color = value == 'Just Booked' ? 'brown' : value == 'Only Phlebotomy Done' ? 'red' :
                            value == 'Lying Pending Results' ? 'purple' : value == 'Results Done But not Signed' ? 'blue' : value == 'Results Ready' ? 'green' : ''

                          return (
                            <>
                              {status_color.trim() != '' ?
                                <TableCell key={column.id} align={column.align} style={{ backgroundColor: status_color, color: 'white' }} >
                                  <i><b>{value}</b></i>
                                </TableCell>
                                : <TableCell key={column.id} align={column.align} >
                                  {value}
                                </TableCell>}
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        }


      </Paper>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={patient_invoice}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setPatientInvoice(false)
          setSelected({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Passenger Invoice</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selected.name != undefined ?
              <center> <Fragment key={"sqq"}>
                <PDFViewer width="1000" height="1000" className="app" >
                  <Invoice invoice={selected} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setPatientInvoice(false)
              setSelected({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={laborder}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setLabOrder(false)
          setSelectedOrders({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Lab Order</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selectOrders.name != undefined ?
              <center> <Fragment key={"sqq"} id>
                <PDFViewer width="1000" height="1000" className="app" >
                  <LabOrder invoice={selectOrders} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setLabOrder(false)
              setSelectedOrders({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={OpenCovidTemplate}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setCovidTemplate(false)
          setSelectedCovid({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Lab Report</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selectedCovid.name != undefined ?
              <center> <Fragment key={"sqwewq"} >
                <GridContainer>
                  <GridItem md={2}>

                    <PDFDownloadLink
                      style={{
                        float: 'left'
                      }}
                      document={<CovidTemplate invoice={selectedCovid} />} fileName={selectedCovid.invoice_id + ".pdf"}>
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                        <Button variant="contained" color="primary">Download Report</Button>)}
                    </PDFDownloadLink>
                    <br />
                    <br />
                    <Button
                      style={{
                        float: 'left'
                      }}
                      variant="contained" color={'secondary'} onClick={() => {
                        setCovidTemplate(false)
                        var temp = selectedCovid
                        localStorage.setItem('covid_report', JSON.stringify(temp))
                        setSelectedCovid({
                          reports: []
                        })
                        //console.log(temp)
                        setTimeout((temp) => {
                          console.log(temp)
                          setSelectedCovid(JSON.parse(localStorage.getItem('covid_report')))
                          setTimeout(() => {
                            setCovidTemplate(true)
                          }, 500);

                        }, 400);
                      }}>
                      Refresh Report
                    <RefreshIcon /></Button>

                  </GridItem>

                  <GridItem md={10}>
                    <PDFViewer width="1000" height="1000" className="app" >
                      <CovidTemplate invoice={selectedCovid} />
                    </PDFViewer>
                  </GridItem>
                </GridContainer>

              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setCovidTemplate(false)
              setSelectedCovid({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={OpenOfficialCovidTemplate}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setOfficialCovidTemplate(false)
          setSelectedOfficialCovidReport({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Lab Report</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selectedOfficialCovidReport.name != undefined ?
              <center> <Fragment key={"sqwewq"} >
                <GridContainer>
                  <GridItem md={2}>

                    <PDFDownloadLink
                      style={{
                        float: 'left'
                      }}
                      document={<OfficialCovidTemplate invoice={selectedOfficialCovidReport} />} fileName={selectedOfficialCovidReport.invoice_id + ".pdf"}>
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                        <Button variant="contained" color="primary">Download Report</Button>)}
                    </PDFDownloadLink>
                    <br />
                    <br />
                    <Button
                      style={{
                        float: 'left'
                      }}
                      variant="contained" color={'secondary'} onClick={() => {
                        setOfficialCovidTemplate(false)
                        var temp = selectedOfficialCovidReport
                        localStorage.setItem('official_covid_report', JSON.stringify(temp))
                        setSelectedOfficialCovidReport({
                          reports: []
                        })
                        //console.log(temp)
                        setTimeout((temp) => {
                          console.log(temp)
                          setSelectedOfficialCovidReport(JSON.parse(localStorage.getItem('official_covid_report')))
                          setTimeout(() => {
                            setOfficialCovidTemplate(true)
                          }, 500);

                        }, 400);
                      }}>
                      Refresh Report
       <RefreshIcon /></Button>

                  </GridItem>

                  <GridItem md={10}>
                    <PDFViewer width="1000" height="1000" className="app" >
                      <OfficialCovidTemplate invoice={selectedOfficialCovidReport} />
                    </PDFViewer>
                  </GridItem>
                </GridContainer>

              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setOfficialCovidTemplate(false)
              setSelectedOfficialCovidReport({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
