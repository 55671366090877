import React, {useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";
import ViewWeekIcon from '@material-ui/icons/ViewWeek';

import ReactLoading from 'react-loading';


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import { Robo } from '../Invoice/firebase';

import Grid from "@material-ui/core/Grid";
import DvrIcon from '@material-ui/icons/Dvr'


import Slide from "@material-ui/core/Slide";

import MaterialTable from "material-table";
import { set } from "date-fns";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    position: "absolute",
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    marginLeft: "13em",
    marginTop: "5em",
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  }
}));
const formatAMPM=(date)=>{
  var hours = date.getHours();
  var minutes = date.getMinutes()
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes
  return strTime;
}
export default function Phelebotomy({invoice_no,BoxClose}) {
  const classes = useStyles();
  const [loading,setLoading]=React.useState(false)
  const [patient_id,setPatientID]=React.useState(0)
  const [patient_name,setPatientName]=React.useState("")
  const [mobile_no,setMobileNo]=React.useState("")
  const [gender,setGender]=React.useState("")
  const [Samples,setSamples]=React.useState([])
  const [selectedSamples,setSelectedSamples]=React.useState([])
  const [selectedRobo7,setSelectedRobo7]=React.useState([])
 
useEffect(()=>{
  if(localStorage.getItem('user_id')==null || localStorage.getItem('user_id')==undefined){
    window.location.href="https://ccp.mpl-labs.pk/login"
  }
  console.log(invoice_no)
  var obj={
    invoice_no:invoice_no
  }
  fetch("https://reports.mpl-labs.pk:8443/4DACTION/Web_PhlebotomyInvoiceTestsCall", {
      method:"POST",
      body:JSON.stringify(obj)
  }).then(res=>res.json()).then((response)=>{
    console.log("Phelebotmy Response API in useEffect 91 :",response)
      setPatientID(response[0].patient_id)
      setPatientName(response[0].patient_name)
      setMobileNo(response[0].mobile_no)
      setGender(response[0].gender)
      var arr=JSON.parse(response[0].ArrayAuxID)
      var temp_samples=[]
      var counter=0
      // jo array hai us me se aisy data nikalna hai
      arr.map((item)=>{
          temp_samples.push({
            ArrayAuxID:JSON.parse(response[0].ArrayAuxID)[counter],
              ArrayTestID:JSON.parse(response[0].ArrayTestID)[counter],
              ArrayTestCode:JSON.parse(response[0].ArrayTestCode)[counter],
              ArrayTestName:JSON.parse(response[0].ArrayTestName)[counter],
              ArrayTestUpdate:JSON.parse(response[0].ArrayTestUpdate)[counter]
          })
          counter=counter+1
      })
      setSamples(temp_samples)

      // BC ROBO 7
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/BC_ROBO_SampleIDsCallingUp/" + invoice_no).then((res) => res.json()).then((sample) => {
  
        var sample_ids=JSON.parse(sample[0].Arr_Sample_ID)
         console.log(sample)
        var BcRobo7=[]
        var counter=0
        sample_ids.map((item)=>{
          var test_title=JSON.parse(sample[0].Test_Codes)[counter]
          var sample_type=JSON.parse(sample[0].Arr_Type_Name)[counter]
          var bottleColor=JSON.parse(sample[0].Arr_RoboCode)[counter]==1 ? "Purple Top" : JSON.parse(sample[0].Arr_RoboCode)[counter]==2 ? 'Yellow Top' : JSON.parse(sample[0].Arr_RoboCode)[counter]==3 ? 'Blue Top' : JSON.parse(sample[0].Arr_RoboCode)[counter]==4 ? 'Green Top' :JSON.parse(sample[0].Arr_RoboCode)[counter]==5 ? 'Grey Top' : JSON.parse(sample[0].Arr_RoboCode)[counter]==6 ? 'Yellow Top': JSON.parse(sample[0].Arr_RoboCode)[counter]==0 ? 'Manual':''
          var desc=JSON.parse(sample[0].Arr_Type_Descp)[counter]+""
          var obj={
            id:item,
            code:JSON.parse(sample[0].Test_Codes)[counter],
            desc:JSON.parse(sample[0].Arr_Type_Descp)[counter],
            bottleColor:bottleColor,
               // for BCrobo 7 29-DEC-22 (1/2)
               refrence_no:sample[0].ref_no!=""?sample[0].ref_no:"Nil",
               // MPL or FSLS name hai ye MPL ID: es me MPL dynamic aa rha
               idTag:sample[0].id_tag,
               // for BCrobo 7 17-JAN-23 (1/2)
               sample_type:sample_type,
            barcode:     <Button variant="contained" style={{backgroundColor:'white', color:'black'}} key={item.invoiceNo} onClick={()=>{
              var obj1={
                sample_id:item,
                age_gender:response[0].gender,
                test_title:test_title,
                date:new Date().ddmmyyy(),
                mpl_id:invoice_no,
                name:(desc.includes("Urine") || desc.includes("Semen") || desc.includes("Stool")) ? "" :response[0].patient_name,
                patient_id:response[0].patient_id,
                time:formatAMPM(new Date()),
                sample_type:sample_type,
                   // for Barcode 29-DEC-22 (1/2)
                   refrence_no:sample[0].ref_no!=""?"REF NO : "+sample[0].ref_no+" | ":"REF NO : "+"Nil   "+" | ",
                   // MPL or FSLS name hai ye MPL ID: es me MPL dynamic aa rha
                     idTag:sample[0].id_tag,
    
              }
              var url="http://localhost:1000/GETBarcode"
              fetch(url,{
                method:"POST",
                body:JSON.stringify(obj1),
                headers:{
                  'Content-Type':"application/json"
                }
              }).then(()=>{
                alert("Print Request Sent")
              })
            }}>BARCODE</Button>
          }
          console.log(obj)
         BcRobo7.push(obj)
          counter=counter+1
        })
        setSelectedRobo7(BcRobo7)
    
    
    })
    
  })
 
},[])

  
  return (
    <div className={classes.root}>
        {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
     <div>
            <Grid container >
            <Grid item xs={2} sm={2} md={2} lg={1}></Grid>          
      <Grid item xs={2} sm={2} md={2} lg={2}>
      <TextField required id="" label="Invoice No" value={invoice_no} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Patient ID" value={patient_id} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Patient Name" value={patient_name}/>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Mobile No" value={mobile_no}/>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Gender" value={gender} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={1}></Grid>          
      </Grid>
 <Grid container style={{
     justifyContent:"space-evenly"
 }}>
 
    <MaterialTable
 fullWidth={true}
      title="Barcode"
      style={{
          width:"60em"
      }}
data={selectedRobo7}
      columns={[
       { title: 'Sample ID', field: 'id' },
       { title: 'Sample Name', field: 'code' },
       { title: 'Sample Description', field: 'desc' },
       { title: 'Barcode', field: 'barcode' },
       { title: 'Bottle Color', field: 'bottleColor' ,editable: 'never', render: rowData => {
        return <p style={{ backgroundColor: rowData.bottleColor=="Manual" ? "black" : rowData.bottleColor.split('Top')[0],  fontWeight: "bold", textTransform:'uppercase', textAlign:"center", color:'white' }}>{rowData.bottleColor}</p>   
      }
    } 
       
      ]}
      actions={[
        {
          tooltip: 'Barcodes',
          icon: ()=><ViewWeekIcon fontSize={"large"} style={{fontSize:"2em"}} />,
          onClick: async(evt, sample) => {
           sample.map((row)=>{
            var obj1={
              sample_id:row.id,
              age_gender:gender,
              test_title:row.code,
              date:new Date().ddmmyyy(),
              mpl_id:invoice_no,
              name:(row.desc.includes("Urine") || row.desc.includes("Semen") || row.desc.includes("Stool")) ? "" : patient_name,
              patient_id:patient_id,
              time:formatAMPM(new Date()),
              // sample_type:row.bottleColor
                  // for Barcode 17-JAN-23 Niche wali line comment ki hai aur sample_type new dala hai
                  sample_type:row.sample_type,
                  // sample_type:row.bottleColor,
                    // for Barcode 29-DEC-22 (2/2)
                     refrence_no:row.refrence_no!=""? "REF NO : "+row.refrence_no+" | ":"REF NO : "+"Nil   "+" |",
                     // MPL or FSLS name hai ye MPL ID: es me MPL dynamic aa rha
                    idTag:row.idTag,
  
            }
            var url="http://localhost:1000/GETBarcode"
            fetch(url,{
              method:"POST",
              body:JSON.stringify(obj1),
              headers:{
                'Content-Type':"application/json"
              }
            }).then(()=>{
              alert("Print Request Sent")
            })

          })
        }
      }
      ]}

      options={{
        selection: true
      }}
    />
 </Grid>

</div>
     
}
</div>
  );
}
